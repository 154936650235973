import React, { useEffect } from 'react'

import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useAboutUs from 'content-queries/mx/about-us'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import Layout from 'mx/layout'

import { MainBanner, MainContent } from '../mx/components/new'

const AboutUsPage = () => {
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'About Us',
    })
  }, [])
  const { data } = useAboutUs()
  const [
    {
      node: {
        sectionModules: [hero, inspired, history, , , banner],
      },
    },
  ] = data
  return (
    <Layout>
      <AutomaticPopup />
      <MainContent header data={hero} />
      <MainContent data={inspired} inline classname={tw`mt-20 lg:mt-32`} />
      <MainBanner brown data={history} classname={tw`mt-20 lg:mt-32`} />
      <MainBanner data={banner} classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}

export default AboutUsPage

export const Head = () => {
  const { data } = useAboutUs()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/about-us"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
